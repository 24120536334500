import {
  Alert,
  Button,
  CircularProgress,
  Snackbar,
  Tab,
  Tabs,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabaseClient } from "../utils/config";
import CustomTab from "../components/custom-tabs/CustomTabs.tsx";
import TemplateCard from "../components/template-card/TemplateCard.jsx";

export default function Emailtemplate() {
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [sentTemplates, setSentTemplates] = useState([]);
  const [loader, setLoader] = useState(true);
  const [value, setValue] = useState(0);
  const [deleteNotifications, setDeleteNotifications] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getTemplates = async () => {
    await supabaseClient
      .from("email_template")
      .select("*")
      .then((e) => {
        const footers = e?.data?.filter((el) => el?.template_type === "custom");
        const sentTemp = e?.data?.filter((el) => el?.template_type === "sent");
        setTemplates(footers);
        setSentTemplates(sentTemp);
        setLoader(false);
      });
  };
  useEffect(() => {
    getTemplates();
  }, []);

  const deleteTemplate = async (temp) => {
    await supabaseClient
      .from("email_template")
      .delete()
      .match({ id: temp?.id })
      .then((e) => {
        setDeleteNotifications(true);
        getTemplates();
      });
  };
  return (
    <div className="h-screen">
      <div className="p-20 flex justify-between">
        <div className="text-black font-semibold">ST Email Templates</div>
        <div>
          <Button
            sx={{
              backgroundColor: "#069d6e",
              color: "white",
              width: 150,
              "&:hover": {
                backgroundColor: "#069d6e",
                color: "white",
              },
            }}
            onClick={() => navigate("/editor")}
          >
            Go To Editor
          </Button>
        </div>
      </div>
      <Snackbar
        open={deleteNotifications}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={() => setDeleteNotifications(false)}
        message={"Template Delete Successfully!"}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
          onClose={() => setDeleteNotifications(false)}
        >
          Template Delete Successfully
        </Alert>
      </Snackbar>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        centered
        sx={{
          "& .Mui-selected": { color: "#000" },
          "& .MuiTabs-indicator": {
            backgroundColor: "#069d6e",
          },
        }}
      >
        <Tab label="Custom Footer" value={0} />
        <Tab label="Sent Emails" value={1} />
      </Tabs>

      <CustomTab value={value} index={0}>
        {loader ? (
          <div className="flex justify-center">
            <CircularProgress
              style={{
                color: "#069d6e",
              }}
            />
          </div>
        ) : templates?.length > 0 ? (
          <div className=" flex flex-wrap p-10 max-md:flex-col">
            {templates?.map((el, index) => {
              return (
                <TemplateCard
                  key={index}
                  data={el}
                  isDelete={false}
                  deleteTemplate={deleteTemplate}
                />
              );
            })}
          </div>
        ) : (
          <div className="flex justify-center text-black">
            No Templates Found
          </div>
        )}
      </CustomTab>
      <CustomTab value={value} index={1}>
        {loader ? (
          <div className="flex justify-center">
            <CircularProgress
              style={{
                color: "#069d6e",
              }}
            />
          </div>
        ) : sentTemplates?.length > 0 ? (
          <div className=" flex flex-wrap p-10 max-md:flex-col">
            {sentTemplates?.map((el, index) => {
              return (
                <TemplateCard
                  key={index}
                  data={el}
                  isDelete={true}
                  deleteTemplate={deleteTemplate}
                />
              );
            })}
          </div>
        ) : (
          <div className="flex justify-center text-black">
            No Sent Templates Found
          </div>
        )}
      </CustomTab>
    </div>
  );
}
