import React from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const CustomBack = ({ onClick }) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
    >
      <ArrowBackIcon />
      Back
    </IconButton>
  );
};

export default CustomBack;
