import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/home";
import { BrowserRouter } from "react-router-dom";
import RoutesComponents from "./route";

function App() {
  return (
    <BrowserRouter>
      <RoutesComponents />
    </BrowserRouter>
  );
}

export default App;
