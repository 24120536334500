import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const TemplateCard = ({ data, isDelete, deleteTemplate }) => {
  const navigate = useNavigate();

  return (
    <Box className="w-1/2 p-5 max-md:w-full">
      {data?.template_name && (
        <Typography className="text-black flex justify-center">
          {data?.template_name}
        </Typography>
      )}
      <Box
        className="h-40 overflow-scroll"
        dangerouslySetInnerHTML={{ __html: data?.html }}
      />
      <Box className="my-3 flex flex-row gap-10">
        <Button
          sx={{
            backgroundColor: "#069d6e",
            color: "white",
            "&:hover": {
              backgroundColor: "#069d6e",
              color: "white",
            },
          }}
          onClick={() => {
            navigate(`/select-recepient`, {
              state: { html: data?.html },
            });
          }}
        >
          Resend
        </Button>
        <Button
          sx={{
            backgroundColor: "#069d6e",
            color: "white",
            "&:hover": {
              backgroundColor: "#069d6e",
              color: "white",
            },
          }}
          onClick={() => {
            localStorage.setItem("design", JSON.stringify(data?.email_json));
            navigate("/editor");
          }}
        >
          Edit
        </Button>
        {isDelete && (
          <Button
            sx={{
              backgroundColor: "red",
              color: "white",
              "&:hover": {
                backgroundColor: "red",
                color: "white",
              },
            }}
            onClick={() => deleteTemplate(data)}
          >
            Delete
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default TemplateCard;
