import React from "react";
import UnAuthorizeIcon from "../images/authorization.png";
export default function UnAuthorized() {
  return (
    <div className="h-screen flex items-center justify-center">
      <div className="flex flex-col items-center">
        <div className="text-6xl font-bold">401</div>
        <div className="text-4xl">Authorization Required</div>
        <img src={UnAuthorizeIcon} alt="Authorization" />
      </div>
    </div>
  );
}
