import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import CustomButton from "../custom-button/CustomButton";
import CustomTextInput from "../common/custom-input/CustomInput";

const CustomModal = ({
  open,
  onClose,
  title,
  content,
  onConfirm,
  confirmText,
  onCancel,
  cancelText,
  setTemplateName,
  templateName,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h4" sx={{ color: "black", marginBottom: 4 }}>
          {title}
        </Typography>
        {content && <p id="modal-modal-description">{content}</p>}
        <CustomTextInput
          value={templateName}
          label={"Template Name"}
          onChange={(e) => setTemplateName(e.target.value)}
        />
        <CustomButton onClick={onConfirm} variant="contained" sx={{ mr: 2 }}>
          {confirmText}
        </CustomButton>
        <CustomButton onClick={onCancel} variant="contained">
          {cancelText}
        </CustomButton>
      </Box>
    </Modal>
  );
};

export default CustomModal;
