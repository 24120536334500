import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmailEditor from "react-email-editor"; // Ensure this import is correct
import { supabaseClient } from "../utils/config";
import { Alert, Box, Snackbar } from "@mui/material";
import CustomButton from "../components/custom-button/CustomButton.jsx";
import CustomModal from "../components/custom-modal/CustomModal.jsx";
import CustomBack from "../components/common/custom-back/CustomBack.jsx";

export default function Home() {
  const [displayNotification, setDisplayNotification] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const emailEditorRef = useRef(null);
  const navigate = useNavigate();
  const [editorKey, setEditorKey] = useState(Date.now());
  const [templateName, setTemplateName] = useState("");
  const [design, setDesign] = useState({
    html: "",
    email_json: "",
  });
  const [preview, setPreview] = useState(false);

  // Function to export HTML
  // const exportHtml = () => {
  //   const unlayer = emailEditorRef.current?.editor;
  //   unlayer?.exportHtml((data) => {
  //     const { design, html } = data;
  //     setDesign({ html: html, email_json: design });
  //     navigate(`/select-recepient`, { state: { html: html } });
  //   });
  // };

  // Function to save design
  const saveDesign = async () => {
    const unlayer = emailEditorRef.current?.editor;
    unlayer?.exportHtml((data) => {
      console.log(data);
      const { design, html } = data;
      setDesign({ html: html, email_json: design });
    });
  };

  // Function to save design to the database
  const saveDatabase = async (html, email_json) => {
    await supabaseClient
      .from("email_template")
      .insert({
        html: html,
        email_json: email_json,
        template_type: "sent",
        template_name: templateName,
      })
      .then((e) => {
        console.log("Email Template Saved");
        setDisplayNotification(true);
        setTimeout(() => {
          navigate(`/select-recepient`, {
            state: { html: html, templateName: templateName },
          });
        }, 2000);
      });
  };

  // Function to toggle preview mode
  const togglePreview = () => {
    const unlayer = emailEditorRef.current?.editor;
    if (preview) {
      unlayer?.hidePreview();
      setPreview(false);
    } else {
      unlayer?.showPreview("desktop");
      setPreview(true);
    }
  };

  // Function to load custom tools
  const loadCustom = () => {
    // const unlayer = emailEditorRef?.current?.editor;
    const design = JSON.parse(localStorage.getItem("design"));
    if (emailEditorRef?.current?.editor && design) {
      //   console.log(design);
      emailEditorRef.current.editor.loadDesign(design);
    }
  };
  const clearLocalStorage = () => {
    localStorage.removeItem("design");
    setEditorKey(Date.now());
  };
  const handleExportConfirm = () => {
    setDisplayModal(false);
    const unlayer = emailEditorRef.current?.editor;
    unlayer?.exportHtml((data) => {
      const { design, html } = data;
      setDesign({ html: html, email_json: design });
      saveDatabase(html, design);
    });
  };

  const handleExportCancel = () => {
    setDisplayModal(false);
    const unlayer = emailEditorRef.current?.editor;
    unlayer?.exportHtml((data) => {
      const { design, html } = data;
      localStorage.setItem("design", JSON.stringify(design));
      navigate(`/select-recepient`, {
        state: { html: html, templateName: templateName },
      });
    });
  };

  // useEffect(() => {
  //   if (design?.email_json !== "" && design?.html !== "") {
  //     saveDatabase();
  //   }
  // }, [design]);

  const exportHtml = () => {
    setDisplayModal(true);
  };

  return (
    <main className="h-full">
      <Snackbar
        open={displayNotification}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={() => setDisplayNotification(false)}
        message={"Email Template Saved Successfully!"}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
          onClose={() => setDisplayNotification(false)}
        >
          Email Template Saved Successfully
        </Alert>
      </Snackbar>
      <Box className="flex flex-row justify-between px-4">
        <CustomBack onClick={() => navigate(-1)} />
        <Box className="flex gap-5 justify-center mt-2 mb-4">
          <CustomButton onClick={clearLocalStorage}>
            Clear the editor
          </CustomButton>

          <CustomButton onClick={togglePreview}>
            {preview ? "Hide" : "Show"} Preview
          </CustomButton>
          <CustomButton onClick={exportHtml}>Send Email</CustomButton>
          {/* <CustomButton onClick={saveDesign}></CustomButton> */}
        </Box>
      </Box>
      <Box className="px-10">
        <EmailEditor
          key={editorKey}
          style={{ width: "100%", height: "100vh" }}
          ref={emailEditorRef}
          onLoad={() => loadCustom()}
        />
        <CustomModal
          open={displayModal}
          onClose={handleExportCancel}
          title="Do you want to save this template?"
          content=""
          onConfirm={handleExportConfirm}
          confirmText="Yes"
          onCancel={handleExportCancel}
          cancelText="No"
          setTemplateName={setTemplateName}
          templateName={templateName}
        />
      </Box>
    </main>
  );
}
