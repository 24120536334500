import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/system";

const CustomButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  backgroundColor: "#069d6e",
  color: "white",
  width: 200,
  height: 50,
  "&:hover": {
    backgroundColor: "#069d6e",
    color: "white",
  },
}));

export default CustomButton;
