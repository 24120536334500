import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Stack,
  Button,
  FormControlLabel,
  Radio,
  IconButton,
  Snackbar,
  Alert,
  Checkbox,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CreatableSelect from "react-select/creatable";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTextInput from "../components/common/custom-input/CustomInput";
import axios from "axios";

function SelectRecepient() {
  const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  const navigate = useNavigate();

  const [subject, setSubject] = useState("");
  const [recepient, setRecepient] = useState([]);
  const [html, setHtml] = useState("");
  const [activeUsers, setActiveUsers] = useState(false);
  const [waitlist, setWaitlist] = useState(false);
  const [sendToAll, setSendToAll] = useState(false);
  const [displayNotification, setDisplayNotification] = useState(false);
  const location = useLocation();
  const getHtml = async () => {
    setHtml(location.state.html);
  };
  console.log(html);
  useEffect(() => {
    getHtml();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const mailData = {
      otherEmails: recepient?.map((item) => item?.label),
      subject: subject,
      waitlist: waitlist,
      activeUsers: activeUsers,
      sendToAll: sendToAll,
      emailTemplate: html,
    };
    console.log(mailData, "mai");
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}email/send-bulk`, mailData)
      .then((e) => {
        setDisplayNotification(true);
        console.log("Email Sent");
      });
  };

  return (
    <Box sx={{ height: "100vh" }}>
      <Snackbar
        open={displayNotification}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setDisplayNotification(false)}
        message={"Email Sent Successfully!"}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
          onClose={() => setDisplayNotification(false)}
        >
          Email Sent Successfully
        </Alert>
      </Snackbar>
      <Box
        sx={{
          width: "70%",
          height: "calc(100vh - 20px)",
          margin: "auto",
          marginTop: "20px",
          paddingTop: "10vh",
          backgroundColor: "#fff",
          borderRadius: 3,
        }}
      >
        <IconButton sx={{ marginLeft: 10 }} onClick={() => navigate(-1)}>
          <ArrowBackIcon height={100} />
        </IconButton>
        <form onSubmit={handleSubmit}>
          <Stack
            spacing={4}
            textAlign={"center"}
            sx={{ margin: "auto !important", width: "80%" }}
          >
            <CustomTextInput
              label="Subject"
              value={subject}
              onChange={(event) => {
                setSubject(event.target.value);
              }}
              required
              sx={{ marginTop: "30px" }}
            />

            <CreatableSelect
              isMulti
              name="Select Recepient"
              options={recepient}
              onChange={setRecepient}
              closeMenuOnSelect={false}
              isValidNewOption={(inputValue) => {
                const isValidEmail = emailRegex.test(inputValue);
                return isValidEmail;
              }}
            />

            <Stack flexDirection={"row"}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={waitlist}
                    onChange={() => {
                      setWaitlist(!waitlist);
                    }}
                  />
                }
                style={{ color: "black" }}
                label="Waitlists"
              />
            </Stack>
            <Box>
              <Button
                type="submit"
                sx={{
                  backgroundColor: "#069d6e",
                  color: "white",
                  width: 200,
                  height: 50,
                  "&:hover": {
                    backgroundColor: "#069d6e",
                    color: "white",
                  },
                }}
              >
                Send
              </Button>
            </Box>
          </Stack>
        </form>
      </Box>
    </Box>
  );
}

export default SelectRecepient;
