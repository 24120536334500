import React from "react";
import TextField from "@mui/material/TextField";

const CustomTextInput = ({ value, onChange, label }) => {
  return (
    <TextField
      value={value}
      onChange={onChange}
      label={label}
      variant="outlined"
      fullWidth
      sx={{
        mb: 2,
        "& label.Mui-focused": {
          color: "#069d6e",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "#069d6e",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#069d6e",
          },
          "&:hover fieldset": {
            borderColor: "#069d6e",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#069d6e",
          },
        },
      }}
    />
  );
};

export default CustomTextInput;
