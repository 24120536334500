import { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "./pages/home";
import SelectRecepient from "./pages/select-recepient";
import Emailtemplate from "./pages/email-template";
import { supabaseClient } from "./utils/config";
import UnAuthorized from "./pages/un-authorize";

export default function RoutesComponents() {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const supabase = supabaseClient;
  const [user, setUser] = useState("");
  const navigate = useNavigate();

  // useEffect(() => {
  //   const storedToken = localStorage.getItem("token");
  //   if (storedToken) {
  //     checkUser(storedToken);
  //   } else if (token) {
  //     localStorage.setItem("token", token);
  //     checkUser(token);
  //   } else {
  //     navigate("/401");
  //   }
  // }, []);

  // const checkUser = (token) => {
  //   supabase.auth
  //     .getUser(token)
  //     .then((e) => {
  //       if (e?.data?.user !== null) {
  //         setUser(e?.data?.user);
  //         const newUrl = "/";
  //         navigate(newUrl, { replace: true });
  //       } else {
  //         navigate("/401");
  //       }
  //     })
  //     .catch((err) => {
  //       navigate("/401");
  //     });
  // };

  return (
    <>
      <Routes>
        <>
          <Route path="/editor" element={<Home />} />
          <Route path="/select-recepient" element={<SelectRecepient />} />
          <Route path="/" element={<Emailtemplate />} />
        </>

        <Route path="/401" element={<UnAuthorized />} />
      </Routes>
    </>
  );
}
